export default {
  data() {
    return {
      texts: "",
      iCurrentPage: 1,
      iNumPages: 0,
      screenWidth: 0,
      bLoading: false,
      isMobileAndTablet: false,
      wareHouseDetailArr: [],
      wareHouseFileDetailArr:[],
      itemHeaderWareHouse:[
       {text: 'Nombre', value:'sName'},
      {text:'Archivo', value:'sFileKey'}]
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
    this.close();
  },
  beforeMount() {
    this.texts = FILE.customerTexts[this.selectLanguage];
  },
  methods: {
    warehousesDetail(item) {
      
      DB.get(`${URI}/api/v1/${this.selectLanguage}/customers/enterprises/${this.$route.params.id}/warehouses/${item.sCustomerWarehouseId}`, {
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
        params: {
        }
      })
        .then((response) => {
          this.wareHouseDetailArr = []
          this.wareHouseDetailArr.push(response.data.results);
        })
        .catch((error) => {
                    this.mixError(error.response.data.message,error.response.status);
        });
    },
    warehouseFileDetail(item){
      DB.get(`${URI}/api/v1/${this.selectLanguage}/customers/enterprises/${this.$route.params.id}/warehouses/${item.sCustomerWarehouseId}/files`, {
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
        params: {
        }
      })
        .then((response) => {
          this.wareHouseFileDetailArr = response.data.results;
          this.iNumPages = response.data.iNumPages;
          this.iTotalItems = response.data.iTotal;
        })
        .catch((error) => {
                    this.mixError(error.response.data.message,error.response.status);
        });
    },
    deleteItem(item) {
      this.$store.commit("setDelete", {
        active: true,
        title: 'Eliminar Almacen',
        // title: this.texts.textDialogDelete,
        descipcion: 'Se eliminara el almacen',
        // descipcion: this.texts.delete.textDescription,
        // {{host}}/api/v1/:sLang/customers/enterprises/:sCustomerEnterpriseId/warehouses/:sCustomerWarehouseId
        api: `${URI}/api/v1/${this.selectLanguage}/customers/enterprises/${item.sCustomerEnterpriseId}/warehouses/${item.sCustomerWarehouseId}`,
      });
     
    },
    downloadFile(fileUrl){
      window.open(fileUrl,'_blank')
   },
    close() {
      this.$store.commit("setDialogDetailsCustomerWareHouse", 
      {active: false,
      arr : []});
    },
    handleResize() {
      if (window.innerWidth >= 960) {
        this.isMobileAndTablet = true
        this.screenWidth = 80;
      } else {
        this.isMobileAndTablet = false
        this.screenWidth = 100;
      }
    },
  },
  computed: {
    selectLanguage() {
      return this.$store.state.language;
    },
    dialogDetailsCustomerWareHouse() {
      return this.$store.state.dialogDetailsCustomerWareHouse;
    },
  },
  watch: {
    selectLanguage() {
      if (this.selectLanguage) {
        this.texts = FILE.customerTexts[this.selectLanguage];
      }
    },
    dialogDetailsCustomerWareHouse: function () {
      if (this.dialogDetailsCustomerWareHouse) {
        this.texts = FILE.customerTexts[this.selectLanguage];
        this.warehousesDetail(this.$store.state.arrDetailCustomerWareHouse);
        this.warehouseFileDetail(this.$store.state.arrDetailCustomerWareHouse);
       
      }
    },
  },
};